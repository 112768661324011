<template>

<!--  <div class="container-fluid fixed-top viendo-navbar">-->
  <div class="container-fluid">
    <div class="container">
      <div class="row justify-content-center">

        <div class="col-12">
          <h2 class="text-uppercase small text-center font-weight-bold pt-4 d-none d-md-block">{{ $t("El mejor canal de cine en español") }}</h2>
        </div>
        <div class="col-12 d-bloc d-md-none" >
          <nav class="navbar navbar-expand  ">
            <div class="container-fluid">
              <router-link :to="{name: 'home'}" class="navbar-link">
                <img height="50px"  src="@/assets/img/logo.png" alt="Viendo Movies Logo" >
              </router-link>
            </div>
            <button @click="menuToggle()" v-if="$isMobile()" class="burguer">
              <i class="bi bi-list"></i>

            </button>
          </nav>
        </div>
        <div class="col-12 mt--3 d-none d-md-block" >
          <nav class="navbar navbar-expand  ">
            <div class="container-fluid">
              <router-link :to="{name: 'home'}" class="navbar-link mr-3">
                <img height="100px" width="72px" src="@/assets/img/viendomovies-logo-white.png" alt="Viendo Movies Logo" >
              </router-link>
              <div class="collapse navbar-collapse " id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                  <!--        <li class="nav-item active">-->
                  <!--          <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>-->
                  <!--        </li>-->
                  <li v-for="(route,index) in menu"
                      v-bind:key="index"
                      v-bind:class="{'active':route.routerName === $route.name}"
                      class="nav-item">
                    <router-link
                        :to="route.linkType == 'page' ? {
                          name: 'page',
                          params:{
                            page: route.routerName
                          }
                        } : route.routerName"
                        class="nav-link">
                      {{route.linkName.toUpperCase()}}
                    </router-link>
                  </li>
<!--                  <li class="nav-item">-->
<!--                    <a v-if="$i18n.locale == 'en'" class="nav-link" @click="changeLocale('es')">{{ $t("Español").toUpperCase() }}</a>-->
<!--                    <a v-else class="nav-link" @click="changeLocale('en')">{{ $t("Ingles").toUpperCase() }}</a>-->
<!--                  </li>-->
                </ul>
                <form class="d-flex" role="search"  @submit.prevent>
                  <div class="searcher">
                    <label for="search" class="search-label"><i class="bi bi-search mr-2"></i></label>
                    <input
                        id="search"
                        v-model="$parent.searchQuery"
                        class="form-control form-control-sm d-inline-block"
                        type="search"
                        :placeholder="$t('Buscar')"
                        :aria-label="$t('Buscar')"
                        @keyup.enter="searchSubmit"
                    >
                  </div>
                  <ul class="navbar-nav mr-auto">
                    <li v-bind:class="{'active':'club' === $route.name}"  class="nav-item">
                      <!-- <router-link
                          to="/club"
                          v-if="!cineClubSubscribed"
                          class="nav-link btn btn-primary">
                        {{$t('Cine Club').toUpperCase()}}
                      </router-link> -->
                      <router-link
                          to="/club"
                          class="nav-link btn btn-primary">
                        {{$t('Cine Club').toUpperCase()}}
                      </router-link>
                    </li>
                    <!-- <li v-bind:class="{'active':'club' === $route.name}"  class="nav-item">
                      <button
                          :title="$t('Logout')"
                          v-if="cineClubSubscribed"
                          @click="logout"
                          class="nav-link btn btn-logout">
                        <i class="bi bi-box-arrow-right"></i>
                      </button>
                    </li> -->
                  </ul>
                </form>

<!--                <div class="my-2 my-0 mx-1">-->
<!--                  <router-link-->
<!--                      :to="{name: 'live'}"-->
<!--                  >-->
<!--                    <img height="25px" width="103px" src="@/assets/img/envivo.png" alt="En Vivo" />-->
<!--                  </router-link>-->
<!--                </div>-->
<!--                <div class="my-2 my-0 mx-1">-->
<!--                  <img height="25px" width="25px"  src="@/assets/img/search_icon.png" alt="">-->
<!--                </div>-->


              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>


  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AppHeader",
  methods:{
    menuToggle(){
      this.$parent.menuToggle()
    },
    changeLocale(locale){
      this.$i18n.locale = locale;
      this.$store.commit('setLocale', locale);
      window.location.reload();
    },
    logout(){
      this.$store.dispatch("logout");
    }
  },
  computed:{
    ...mapGetters(['cineClubSubscribed']),
    menu(){
      return this.$parent.headerMenu
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss">
  input, textarea {
    outline: none;
    box-shadow:none !important;
  }
  .nav-item .nav-link.btn-logout{
    font-size: 1.2rem;
    padding: 6px;
    margin-left: 10px;
    line-height:1.2rem;
    &:focus{
      box-shadow: none;
    }
  }
  #search{
    width: 0px;
    opacity: 0;
    background: transparent !important;
    color: #fff;
    border:none;
    transition: width 800ms;
    border-bottom: 1px solid #ffffff;
    &:focus{
      width: 150px;
      opacity: 1;
      transition: width 800ms;
    }
    &::placeholder {
      color: #00000000;
    }
    &:not(:placeholder-shown) {
      width: 150px;
      opacity: 1;
      transition: width 800ms;
    }
  }

  .search-label{
    margin-bottom: 0px;
    vertical-align: bottom;
  }
  .burguer{
    float: right;
    background: none;
    border: none;
    color: white;
    font-size: 2em;
  }
</style>