export default {
    //re-format state data
    slides: state => state.slides,
    sections: state => state.sections,
    programming: state => state.programming,
    releases: state => state.releases,
    onDemand: state => state.onDemand,
    awards: state => state.awards,
    award: (state,id) => {
        if(typeof state.awards[id] === "undefined"){
            return null
        }else{
            return state.awards[id]
        }
    },
    awardsAmerica: state => state.awardsAmerica,
    awardsEurope: state => state.awardsEurope,
    news: (state) => {
        return  Object.values(state.news[state.locale]);
    },
    clubHome: (state) => {
        return  state.clubHome[state.locale] ? state.clubHome[state.locale] : null;
    },
    // menus: state => state.menus,
    header_menu: state => state.header_menu,
    footer_menu: state => state.footer_menu,
    pages: state => state.pages,
    translated_pages: state => state.translated_pages,
    faqs: state => state.faqs,
    locale: state => state.locale,
    cineClubSubscribed: state => state.cine_club_subscribed,
    bearer_token: state => state.bearer_token
};