import axios from "axios";
import PageModel from "../models/PageModel";

function getRequestHeaders(context){
    let headers = {
        "Accept-Language": context.state.locale
    };

    if (context.state.bearer_token){
        headers["Authorization"] = "Bearer "+context.state.bearer_token;
    }

    return headers;
}

export default {

    fetchSearch: function(context,query){
        let url = "/api/search?q="+query;
        return axios({
                headers: getRequestHeaders(context),
                url: url
            })
            .then(response => response.data)
            .then(data => {
                return data
                }
            );
    },

    fetchHome: function(context) {
        let url = "/api/home";

        return axios({
                headers: getRequestHeaders(context),
                url: url
            })
            .then(response => response.data)
            .then(data => {

                context.commit("setHeaderMenu",data.header_menu);
                context.commit("setFooterMenu",data.footer_menu);
                context.commit("setSlides",data.slides);
                context.commit("setSections",data.sections);
                context.commit("setProgramming",data.programming);
                    return data;
                }
            );
    },

    fetchFaqs: function(context){

        let url = "/api/faq";

        return axios({
                headers: getRequestHeaders(context),
                url: url
            })
            .then(response => response.data)
            .then(data => {
                context.commit("setFaqs", data.data);
                return data;
            });
    },
    fetchPage: function(context,slug){

        let url = "/api/pages/"+slug;

        return axios({
                headers: getRequestHeaders(context),
                url: url
            })
            .then(response => response.data)
            .then(data => {

                    context.commit("setPage", new PageModel(data));
                    return data;
                }
            ).catch((e) => {
                context.commit("setPage", PageModel.getStatusPage(slug, e.response.status));
            });
    },

    fetchAward: function(context,slug) {
        let url = "/api/awards/"+slug;
        return axios({
                    headers: getRequestHeaders(context),
                    url: url
                })
                    .then(response => response.data)
                    .then(data => {
                            context.commit("saveAward",data)
                            return data;
                        }
                    );
        /*return (typeof context.state.awards[context.state.locale] !== "undefined" && typeof context.state.awards[context.state.locale][slug] !== "undefined"
                ? context.state.awards[context.state.locale][slug]
                : axios({
                        headers: getRequestHeaders(context),
                        url: url
                    })
                    .then(response => response.data)
                    .then(data => {
                            context.commit("saveAward",data)
                            return data;
                        }
                    )
        );*/
    },

    fetchMovie: function(context,slug) {
        let url = "/api/movies/"+slug;
        return (typeof context.state.movies[context.state.locale] !== "undefined" && typeof context.state.movies[context.state.locale][slug] !== "undefined"
                ? context.state.movies[context.state.locale][slug]
                : axios({
                        headers: getRequestHeaders(context),
                        url: url
                    })
                    .then(response => response.data)
                    .then(data => {
                            context.commit("saveMovie",data)
                            return data;
                        }
                    )
        );
    },

    fetchNews: function(context) {
        let url = "/api/news";
        return axios({
                headers: getRequestHeaders(context),
                url: url
            })
            .then(response => response.data)
            .then(response => response.data)
            .then(data => {
                data.forEach((news)=>{
                    context.commit("saveNews",news)
                })
                    // context.commit("saveNews",data)
                    // return data;
                }
            )

    },

    fetchClubHome: function (context){
        let url = "/api/club";
        return axios({
            headers: getRequestHeaders(context),
            url: url
        })
        .then(response => {
                console.log(response.data)
                context.commit("saveClubHome",response.data);

                // context.commit("saveNews",data)
                // return data;
            }
        ).catch(()=>{
            context.commit("saveClubHome",null);
            context.commit('setCineClubSubscribed', false);
        })
    },

    fetchNewsSlug: function(context,slug) {
        let url = "/api/news/"+slug;
        return (typeof context.state.news[context.state.locale] !== "undefined" && typeof context.state.news[context.state.locale][slug] !== "undefined"
                ? context.state.news[context.state.locale][slug]
                : axios({
                        headers: getRequestHeaders(context),
                        url: url
                    })
                    .then(response => response.data)
                    .then(data => {
                            context.commit("saveNews",data)
                            return data;
                        }
                    )
        );
    },

    fetchProgramming: function(context,date) {
        date = (date !== '' ? '?limit=100&date='+date : '?limit=4')
        let url = "/api/programming"+date;
        return axios({
                headers: getRequestHeaders(context),
                url: url
            })
            .then(response => response.data)
            .then(response => response.data)
            .then(data => {
                    return data;
                }
            );
    },

    postForm: function(context, formData){
        return axios({
                method: 'post',
                url: '/api/contact',
                data: formData,
                headers: getRequestHeaders(context),
            })
            .then(response => {
                return response;
            }
            );
    },


    login: function(context, formData){
        return axios({
            method: 'post',
            url: '/api/login',
            data: formData,
            headers: getRequestHeaders(context),
        })
        .then(response => {
                context.commit("setToken",response.data.token);
                context.commit('setCineClubSubscribed', true);
                return response;
            }
        );
    },

    logout: function(context){
        return axios({
            method: 'get',
            url: '/api/logout',
            headers: getRequestHeaders(context),
        })
            .finally(()=>{
                    context.commit("setToken",null);
                    context.commit('setCineClubSubscribed', false);
                }
            );
    },


    register: function(context, formData){
        return axios({
            method: 'post',
            url: '/api/register',
            data: formData,
            headers: getRequestHeaders(context),
        })
        .then(response => {
            context.commit("setToken",response.data.token);
            context.commit('setCineClubSubscribed', true);

            return response;
            }
        );
    },

    forgotPassword: function(context, formData){
        return axios({
            method: 'post',
            url: '/api/forgot-password',
            data: formData,
            headers: getRequestHeaders(context),
        });
    },

    resetPassword: function(context, formData){
        return axios({
            method: 'post',
            url: '/api/reset-password',
            data: formData,
            headers: getRequestHeaders(context),
        }).then(response => {
                context.commit("setToken",response.data.token);
                context.commit('setCineClubSubscribed', true);
                return response;
            }
        );
    },

    validateResetToken: function(context, formData){
        return axios({
            method: 'post',
            url: '/api/validate-password-token',
            data: formData,
            headers: getRequestHeaders(context),
        });
    },


};