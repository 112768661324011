import moment from "moment-timezone";
import Vue from "vue";
Number.prototype.toDurationLength = function(locale) {
    if (!locale){
        locale = "es";
    }
    if(this <= 0){
        return '0 min'
    }
    return moment.duration({"seconds": this}).locale(locale).humanize()
}

Vue.prototype.openVideo = function(mov){
    this.$root.$children[0].playVideo(mov);
}

String.prototype.toHash = function() {
    var hash = 0, i, chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
        chr   = this.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
String.prototype.toProgrammingDate = function(locale) {
    if (!locale){
        locale = "es";
    }
    let date = new Date(this);
    let d = moment(date);
    /*let datex = d.format("Y-M-D HH:mm");
    let fd = new Date(datex+' EDT');
    let june = moment(fd,"America/New_York");
    let edt = june.tz('America/New_York').locale(locale).format('hh:mm a z');
    // let pdt = june.tz('America/Los_Angeles').format('hh:mm a z');*/
    return d.format('hh:mm a z');
}

String.prototype.toDayDate = function(locale) {
    if (!locale){
        locale = "es";
    }
    let date = new Date(this);
    let d = moment(date);
    let datex = d.format("Y-M-D");
    let fd = new Date(datex+' EDT');
    let june = moment(fd,"America/New_York");
    let edt = june.tz('America/New_York').locale(locale).format('YYYY-MM-DD');
    // let pdt = june.tz('America/Los_Angeles').format('hh:mm a z');
    return edt;
}



String.prototype.toDayMonthDate = function(locale) {
    if (!locale){
        locale = "es";
    }
    let june = moment(this);
    let edt = june.locale(locale).format('dddd DD MMMM');
    return edt;
}



Array.prototype.last = function() {
    return this[this.length - 1];
}