
class PageModel {

    content             = "";
    meta_description    = "";
    meta_title          = "";
    slug                = "";
    title               = "";
    status_code         = 200;
    
    constructor(data){
        if(data){
            this.content = data.content.replace(/\u2028/g," ");
            this.meta_description = data.meta_description;
            this.meta_title = data.meta_title;
            this.slug = data.slug;
            this.title = data.title;
        }
    }

    static getStatusPage(slug, status_code){
        var pageModel = new PageModel();
        pageModel.slug = slug;
        pageModel.status_code = status_code;
        return pageModel;
    }

}

export default PageModel;