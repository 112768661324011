export default {
    bearer_token: null,
    locale: 'es',
    slides: [],
    sections: [/*
        {
            content: [],
            element_layout: {
                default: "",
                type: String
            },
            has_more: Boolean,
            layout: {
                default: "",
                type: String
            },
            more: {
                default: "",
                type: String
            },
            title: {
                default: "",
                type: String
            },
            country: {
                default: "",
                type: String
            },
            total: Number,
            type: {
                default: "",
                type: String
            },
        }*/
    ],
    footer_menu:[],
    header_menu:[],
    cine_club_subscribed: false,
    pages: [],// deprecated, use translated_pages instead
    translated_pages: {
        en: [],
        es: []
    },
    faqs: [],
    programming: [],
    releases : [
        {
            id: 1,
            title: "Movie Title",
            thumbnail : "/img/movies/movie-1.jpg",
            genres: "Drama, Misterio",
            length: "90 MIN",
            country: "España",
            awards: ""
        },
        {
            id: 2,
            title: "Movie Title",
            thumbnail : "/img/movies/movie-2.jpg",
            genres: "Drama, Misterio",
            length: "90 MIN",
            country: "España",
            awards: ""
        },
        {
            id: 3,
            title: "Movie Title",
            thumbnail : "/img/movies/movie-3.jpg",
            genres: "Drama, Misterio",
            length: "90 MIN",
            country: "España",
            awards: ""
        },
        {
            id: 4,
            title: "Movie Title",
            thumbnail : "/img/movies/movie-1.jpg",
            genres: "Drama, Misterio",
            length: "90 MIN",
            country: "España",
            awards: ""
        }
    ],
    onDemand : [
        {
            id: 1,
            title: "Movie Title",
            thumbnail : "/img/movies/movie-1.jpg",
            genres: "Drama, Misterio",
            length: "90 MIN",
            country: "España",
            awards: ""
        },
        {
            id: 2,
            title: "Movie Title",
            thumbnail : "/img/movies/movie-2.jpg",
            genres: "Drama, Misterio",
            length: "90 MIN",
            country: "España",
            awards: ""
        },
        {
            id: 3,
            title: "Movie Title",
            thumbnail : "/img/movies/movie-3.jpg",
            genres: "Drama, Misterio",
            length: "90 MIN",
            country: "España",
            awards: ""
        },
        {
            id: 4,
            title: "Movie Title",
            thumbnail : "/img/movies/movie-1.jpg",
            genres: "Drama, Misterio",
            length: "90 MIN",
            country: "España",
            awards: ""
        }
    ],
    awardsAmerica : [
        {
            id : 1,
            image : "/img/awards-america/award-ariel.png",
            name : "Premios Ariel"
        },
        {
            id : 2,
            image : "/img/awards-america/award-guadalajara.png",
            name : "Festival Internacional de Cine de Guadalajara"
        },
        {
            id : 3,
            image : "/img/awards-america/awards-Toronto.png",
            name : "Festival Internacional de Cine Toronto"
        },
        {
            id : 4,
            image : "/img/awards-america/award-premios-platino.png",
            name : "Premios Platino"
        },
        {
            id : 5,
            image : "/img/awards-america/award-morelia.png",
            name : "Festival Internacional de Cine de Morelia"
        },
        {
            id : 6,
            image : "/img/awards-america/award-miff.png",
            name : "Festival Internacional de Cine de Miami"
        },
    ],
    awardsEurope : [
        {
            id : 7,
            image : "/img/awards-emea/award-malaga.png",
            name : "Festival de Cine de Málaga"
        },
        {
            id : 8,
            image : "/img/awards-emea/award-berlin.png",
            name : "Festival Internacional de Cine de Berlín"
        },
        {
            id : 9,
            image : "/img/awards-emea/award-Goya.png",
            name : "Premios Goya"
        },
        {
            id : 10,
            image : "/img/awards-emea/award-venezia.png",
            name : "Festival Internacional de Cine de Venecia"
        },
        {
            id : 11,
            image : "/img/awards-emea/award-Cannes.png",
            name : "Festival de Cannes"
        },
        {
            id : 12,
            image : "/img/awards-emea/award-huelva.png",
            name : "Festival de Cine Iberoamericano de Huelva"
        },
    ],
    awards: {
        en: {},
        es: {}
    },
    movies:{
        en: {},
        es: {}
    },
    news : {
        en: {},
        es: {}
    },
    clubHome: {
        en: {},
        es: {}
    }
};