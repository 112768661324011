import SlideModel from "../models/SlideModel";

export default {

    destroySore: function() {
        try {
            sessionStorage.removeItem("viendoStore");
        } catch (e) {
            console.log("Error destroying storage",e);
        }

    },
    initStore: function(state) {
        try {
            if (sessionStorage.getItem("viendoStore")) {
                this.replaceState(
                    Object.assign(state, JSON.parse(sessionStorage.getItem("viendoStore")))
                );
            }
        }catch (e) {
            console.log("Error gettin data",e);
        }
    },

    setSlides: function(state,slides) {
        state.slides = slides.map((slide)=>{
            return new SlideModel(slide);
        });
    },

    // setMenus: function(state,menu) {
    //     state.menus = menu.map((m)=>{
    //         m.data = {
    //             title: "",
    //             slug: "",
    //             content: "",
    //             meta_title: "",
    //             meta_description: ""
    //         }
    //         return m
    //     });

    // },
    // setMenu: function(state,menu){
    //  state.menus.find( m => m.slug === menu.slug).data = menu
    // },

    setHeaderMenu: function(state, menu_items){
        state.header_menu = menu_items;
    },

    
    setFooterMenu: function(state, menu_items){
        state.footer_menu = menu_items;
    },

    setPage: function(state,page){
        if (!state.translated_pages[state.locale]){
            state.translated_pages[state.locale] = [];
        }
        let _page = state.translated_pages[state.locale].find( m => m.slug === page.slug);
        if(_page){
            _page.data = page;
        }else{
            state.translated_pages[state.locale].push({title: page.title, slug: page.slug, data: page});
        }
    },

    setFaqs: function(state, faqs){
        state.faqs = faqs;
    },

    setSections: function(state,sections) {
        state.sections = sections
    },

    setProgramming: function(state,programming){
        state.programming = programming
    },

    saveAward: function(state,award){
        if (!state.awards[state.locale]){
            state.awards[state.locale] = {};
        }
        state.awards[state.locale][award.slug] = award;
    },

    saveMovie: function(state,movie){
        if (!state.movies[state.locale]){
            state.movies[state.locale] = {};
        }
        state.movies[state.locale][movie.slug] = movie;
    },

    saveNews: function(state,news){
        if (!state.news[state.locale]){
            state.news[state.locale] = {};
        }
        state.news[state.locale][news.slug] = news;
    },

    saveClubHome: function(state,club){
        // if (!state.clubHome[state.locale]){
        //     state.clubHome[state.locale] = {};
        // }
        state.clubHome[state.locale] = club;
    },



    setLocale: function(state,locale){
        state.locale = locale;
    },

    setCineClubSubscribed: function(state, subscribed){
        state.cine_club_subscribed = subscribed;
    },

    setToken: function (state, token){
        state.bearer_token = token;
    }
};