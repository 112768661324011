import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

Vue.use(Vuex);

const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
});

store.subscribe((mutation, state) => {
    try {
        sessionStorage.setItem("viendoStore", JSON.stringify(state));
    } catch (e) {
        console.log("Error saving data",e);
    }
});

export default store;
