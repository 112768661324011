import Vue from "vue"
import Router from "vue-router"
import routes from "./routes";

Vue.use(Router)



let router = new Router({
    mode : 'history',
    scrollBehavior() {
        return {
            behavior: 'smooth',
            x: 0,
            y: 0
        };
    },
    routes
})

export default router;