
class SlideModel {

    title_image    = null;
    image_lg    = null;
    image_md    = null;
    image_sm    = null;
    image_xl    = null;
    title       = null;
    url         = null;
    video       = null;
    
    constructor(data){
        this.title_image = data.title_image;
        this.image_lg = data.image_lg;
        this.image_md = data.image_md;
        this.image_sm = data.image_sm;
        this.image_xl = data.image_xl;
        this.title = data.title;
        this.url = data.url;
        this.video = data.video;
    }


}
export default SlideModel;