<template>
  <div id="app" class="d-flex flex-column min-vh-100 ">
    <transition name="fade" mode="out-in">
      <div class="video-modal p-5" v-if="videoUrl">


        <button type="button" class="btn btn-xl d-block close-button" @click="videoUrl = ''">
          <svg x="0px" y="0px" class="x-icon" viewBox="0 0 1000 1000" xml:space="preserve">
            <g>
              <path
                d="M951.5,309.3c-24.7-58.4-60-110.7-105-155.7c-45-45-97.4-80.3-155.7-105C630.3,23,566.1,10,500,10c-66.1,0-130.3,13-190.7,38.5c-58.4,24.7-110.8,60-155.7,105c-45,45-80.3,97.4-105,155.7C23,369.7,10,433.9,10,500c0,66.1,13,130.3,38.5,190.7c24.7,58.4,60,110.7,105,155.7c45,45,97.4,80.3,155.7,105C369.7,977,433.9,990,500,990c66.1,0,130.3-13,190.7-38.5c58.4-24.7,110.8-60,155.7-105c45-45,80.3-97.4,105-155.7C977,630.3,990,566.1,990,500C990,433.9,977,369.7,951.5,309.3z M500,922.5C267.1,922.5,77.5,733,77.5,500S267.1,77.5,500,77.5C732.9,77.5,922.5,267,922.5,500C922.5,733,732.9,922.5,500,922.5z M652.7,294.5C652.7,294.5,652.7,294.5,652.7,294.5l-157,157.3l-157.3-157c-13.6-13.6-35.7-13.6-49.3,0c0,0,0,0,0,0c-13.6,13.6-13.6,35.7,0,49.3l157.3,157l-157,157.3c-13.6,13.6-13.6,35.7,0.1,49.3c13.6,13.6,35.7,13.6,49.3,0c0,0,0,0,0,0l157-157.3l157.3,157c13.6,13.6,35.7,13.6,49.3,0c0,0,0,0,0,0c13.6-13.6,13.5-35.7-0.1-49.3L545,501.1l157-157.3c13.6-13.6,13.6-35.7-0.1-49.3C688.3,280.9,666.3,280.9,652.7,294.5z" />
            </g>
          </svg>
        </button>
        <vimeo-player ref="player" class="vimeo" :autoplay="true" :video-url="videoUrl" />


      </div>
    </transition>
    <app-header></app-header>
    <transition :duration="{ enter: 500, leave: 0 }" name="fade" mode="out-in">
      <router-view class="" :key="$route.fullPath" />
    </transition>
    <app-footer></app-footer>

    <div class="mobile-menu animate__animated "
      v-bind:class="{ 'animate__fadeInDown': showMenu, 'animate__fadeOutUp': hideMenu, }" v-if="showMenu">
      <div class="container px-4 ">
        <div class="row">
          <div class="col-12 d-bloc d-md-none">
            <nav class="navbar navbar-expand  ">
              <div class="container-fluid">
                <router-link :to="{ name: 'home' }" class="navbar-link">
                  <img height="50px" src="@/assets/img/logo.png" alt="Viendo Movies Logo">
                </router-link>
              </div>
              <button @click="menuToggle()" v-if="$isMobile()" class="burguer">
                <i class="bi bi-x-lg"></i>
              </button>

            </nav>

            <ul class="menu mt-4">
              <li v-if="$isMobile()" @click="menuToggle({ routerName: '/search' })">{{ $t("Buscar").toUpperCase() }}
              </li>
              <li v-if="$isMobile()" @click="menuToggle({ routerName: '/club' })">{{ $t("Cine Club").toUpperCase() }}
              </li>
              <li class="menu" v-for="(route, i) in headerMenu" v-bind:key="i" @click="menuToggle(route)">
                {{ route.linkName.toUpperCase() }}
              </li>
            </ul>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import AppHeader from "@/layout/AppHeader";
import AppFooter from "@/layout/AppFooter";
import 'animate.css';
import { mapGetters } from "vuex";
export default {
  name: 'App',
  components: { AppHeader, AppFooter },
  watch: {
    searchQuery() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        console.log("Move to", this.searchQuery)
        this.$router
          .push({ name: "search", params: { query: this.searchQuery } })
          .catch(() => {
          });
      }, 600)
    }
  },
  data() {
    return {
      timer: null,
      searchQuery: "",
      showMenu: false,
      hideMenu: true,
      videoUrl: null,
      // menu: [

      //   {
      //     routerName : "aboutUs",
      //     linkName : "Quiénes Somos"
      //   },
      //   {
      //     routerName : "programming",
      //     linkName : "Programación"
      //   },
      //   {
      //     routerName : "club",
      //     linkName : "Cine Club"
      //   },
      //   // {
      //   //   routerName : "live",
      //   //   linkName : "En Vivo"
      //   // },
      //   // {
      //   //   routerName : "search",
      //   //   linkName : "search"
      //   // },
      // ]
    }
  },
  created() {
    //this.$store.dispatch("fetchMenus")
  },
  methods: {
    menuToggle(route = null) {
      if (route) {
        if (route.linkType == 'page') {
          this.$router.push({ name: "page", params: { page: route.routerName } });
        } else {
          this.$router.push(route.routerName);
        }
      }
      if (this.showMenu) {
        this.hideMenu = true
        setTimeout(() => {
          this.showMenu = false;
        }, 300);
      } else {
        this.hideMenu = false;
        this.showMenu = true;
      }


    },
    playVideo: function (e) {
      console.log("playVideoEvent", e)
      if (e.trailer) {
        this.videoUrl = e.trailer;
      }
      if (e.video) {
        this.videoUrl = e.video;
      }

    }
  },
  beforeCreate() {
    this.$store.dispatch("fetchHome")
  },
  computed: {
    ...mapGetters(["header_menu"]),
    headerMenu() {
      return this.header_menu.map(menu => {
        return {
          routerName: menu.slug,
          linkName: menu.title,
          linkType: menu.type
        }
      })

    }
  }

}
</script>

<style lang="scss">
@import "src/assets/styles/colors";

ul.menu {
  list-style: none;
  padding: 0;

  li {
    list-style: none;
    border-bottom: 1px solid #efefef33;
    padding: 1em;

    margin: 1em;

    &:hover {
      color: $light-blue;
    }

    color: $white;


  }
}

.video-modal {
  background: rgba(8, 21, 48, 0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;

  .close-button {
    width: 80px;
    float: right;
    position: relative;
    margin-bottom: -80px;
    margin-top: -30px;
    z-index: 99;

    .x-icon {
      fill: $white;
      width: 100%;
      transition: fill 300ms ease-in-out;
    }

    &:hover {
      .x-icon {
        fill: $light-blue
      }
    }
  }

  .vimeo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 768px) {

  .video-modal {
    padding: 0px !important;
  }

  .video-modal .close-button {
    position: absolute;
    margin-top: 250px;
    margin-left: -40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
  }

}


.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}

.mobile-menu {
  --animate-duration: 300ms;
  --animate-delay: 0.3s;
  background: $dark-blue;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
</style>
