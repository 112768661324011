import Vue from 'vue'
import App from './Router.vue'
import router from "@/router/index";
import "@/assets/styles.scss"
import "bootstrap-icons/font/bootstrap-icons.scss"

import VueSplide from '@splidejs/vue-splide';
import VueMobileDetection from "vue-mobile-detection";
import { parseTranslations } from './utilities/locale';

import vueVimeoPlayer from 'vue-vimeo-player'
import translations from './translations/translations'
import VueI18n from 'vue-i18n';

import "@/utilities/prototypes.js";
import axios from "axios";
import VueGtag from "vue-gtag";
import store from "@/store/store";

Vue.config.productionTip = false

axios.defaults.baseURL = "https://viendomovies.com/";
//axios.defaults.baseURL = "http://viendo.local/";

store.commit("initStore");
Vue.use(VueI18n)
Vue.use(vueVimeoPlayer)
Vue.use(VueSplide)
Vue.use(VueMobileDetection);

const i18n = new VueI18n({
  locale: store.state.locale,
  messages: parseTranslations(translations),
  formatFallbackMessages: true
});


Vue.use(VueGtag, {
  config: { id: "UA-58366659-3" }
}, router);


new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')


