const Home = () => import( /*webpackChunkName: "home" */ "@/pages/Home.vue");
const Faqs = () => import(/* webpackChunkName: "faqs"*/ "@/pages/Faqs.vue");
const Programming = () => import(/*webpackChunkName: "programming"*/ "@/pages/Programming.vue");
const Search = () => import(/*webpackChunkName: "search"*/ "@/pages/Search.vue");
const Live = () => import(/*webpackChunkName: "live"*/ "@/pages/Live.vue");
const Awards = () => import(/*webpackChunkName: "awards"*/ "@/pages/Awards.vue");
const Movie = () => import(/*webpackChunkName: "moviePage"*/ "@/pages/MoviePage.vue");
const News = () => import(/*webpackChunkName: "newsPage"*/ "@/pages/NewsPage.vue");
const Pages = () => import(/*webpackChunkName: "pages"*/ "@/pages/Pages.vue");
const PasswordReset = () => import(/*webpackChunkName: "passwordReset"*/ "@/pages/PasswordReset.vue");
const ForgotPassword = () => import(/*webpackChunkName: "passwordForgot"*/ "@/pages/ForgotPassword.vue");

const ContactUs = () => import( /*webpackChunkName: "contactus" */ "@/pages/ContactUs.vue");
const Adquisitions = () => import( /*webpackChunkName: "adquisitions" */ "@/pages/Adquisitions.vue");

const CineClub = () => import( /*webpackChunkName: "cineclub" */ "@/pages/CineClub.vue");



const Jobs = () => import( /*webpackChunkName: "jobs" */ "@/pages/Jobs.vue");


export default [
        {
            path : "/",
            component: Home,
            name: "home"
        },
        {
            path : "/web",
            component: Home,
            name: "home"
        },
        // {
        //     path : "/about",
        //     component: AboutUs,
        //     name : "aboutUs"
        // },
        {
            path : "/programming",
            component: Programming,
            name : "programming"
        },
        {
            path : "/live",
            component: Live,
            name : "live"
        },
        {
            path : "/search/:query",
            component: Search,
            name : "search",
            props: true
        },
        {
            path : "/search",
            component: Search,
            name : "search",
            props: true
        },
        {
            path : "/award/:slug",
            component : Awards,
            name: "award",
            props: true
        },
        {
            path : "/movie/:slug",
            component : Movie,
            name: "movie",
            props: true
        },
        {
            path : "/news/:slug",
            component : News,
            name: "news",
            props: true
        },
        {
            path : "/faqs",
            component: Faqs,
            name : "faqs"
        },

        // PAGES WITH FORMS
        {
            path : "/contact",
            component: ContactUs,
            name : "contact"
        },
        {
            path : "/acquisitions",
            component: Adquisitions,
            name : "adquisitions"
        },
        {
            path : "/jobs",
            component: Jobs,
            name : "jobs"
        },
        {
            path : "/club",
            component: CineClub,
            name : "club"
        },
        {
            path : "/:page",
            component : Pages,
            name: "page",
            props: true
        },
        {
            path : "/password/reset/:token",
            component : PasswordReset,
            name: "password-reset",
            props: true
        },
        {
            path : "/password/reset",
            component : PasswordReset,
            name: "password-reset",
            props: true
        },
        {
            path : "/password/forgot",
            component : ForgotPassword,
            name: "forgot-password",
            props: true
        }
    ];
